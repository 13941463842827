/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import clsx from "clsx";
import React from "react";
import {createUseStyles} from "react-jss";
import UI from "../UI";

type Heading = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

const useStyles = createUseStyles({
    root: {
        marginTop: 0,
        marginBottom: "1rem",
        fontWeight: "normal",
        lineHeight: "1.6em"
    },
    h1: {
        fontFamily: UI.fonts.neoTechStd,
        letterSpacing: 1.5,
        fontSize: "5.9375rem",
        fontWeight: 300,
        textTransform: "lowercase",
        textAlign: "center",
        "&:lang(ja)": {
            fontSize: "4rem"
        },
        [UI.media.only("xs")]: {
            fontSize: "3.5rem",
            "&:lang(ja)": {
                fontSize: "2rem"
            }
        },
        [UI.media.only("sm")]: {
            fontSize: "5rem",
            "&:lang(ja)": {
                fontSize: "3.5rem"
            }
        }
    },
    h2: {
        fontFamily: UI.fonts.neoTechStd,
        fontSize: "2.25rem",
        letterSpacing: 1,
        textTransform: "lowercase",
        "&:last-child": {
            marginBottom: 0
        },
        [UI.media.only("xs")]: {
            letterSpacing: ".05rem",
            lineHeight: 1.33
        },
        "&:lang(ja)": {
            fontSize: "1.8rem"
        }
    },
    h3: {
        fontSize: "1.875rem",
        "&:lang(ja)": {
            fontSize: "1.6rem"
        }
    },
    h4: {
        fontSize: "1.625rem",
        "&:lang(ja)": {
            fontSize: "1.4rem"
        }
    },
    h5: {
        fontSize: "1.375rem"
    },
    h6: {
        fontSize: "1.25rem"
    },
    noBottomMargin: {
        marginBottom: 0
    }
});

interface HeadingProps {
    className?: string;
    children: string | JSX.Element | Array<JSX.Element | string>;
    noMarginBottom?: boolean;
}

function headerClasses(classes: ReturnType<typeof useStyles>, props: HeadingProps, size: Heading) {
    return clsx(classes.root, classes[size], props.className, {
        [classes.noBottomMargin]: props.noMarginBottom
    });
}

export function H1(props: HeadingProps) {
    const classes = useStyles();
    return <h1 className={headerClasses(classes, props, "h1")}>{props.children}</h1>;
}

export function H2(props: HeadingProps) {
    const classes = useStyles();
    return <h2 className={headerClasses(classes, props, "h2")}>{props.children}</h2>;
}

export function H3(props: HeadingProps) {
    const classes = useStyles();
    return <h3 className={headerClasses(classes, props, "h3")}>{props.children}</h3>;
}

export function H4(props: HeadingProps) {
    const classes = useStyles();
    return <h4 className={headerClasses(classes, props, "h4")}>{props.children}</h4>;
}

export function H5(props: HeadingProps) {
    const classes = useStyles();
    return <h5 className={headerClasses(classes, props, "h5")}>{props.children}</h5>;
}

export function H6(props: HeadingProps) {
    const classes = useStyles();
    return <h6 className={headerClasses(classes, props, "h6")}>{props.children}</h6>;
}
