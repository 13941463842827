/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import chroma, {Color as ChromaColor} from "chroma-js";

class Color {
    public static hex(hex: string): Color {
        return new Color(chroma(hex));
    }

    public static rgb(r: number, g: number, b: number): Color {
        return new Color(chroma.rgb(r, g, b));
    }

    private readonly chromaColor: ChromaColor;

    constructor(chromaColor: ChromaColor) {
        this.chromaColor = chromaColor;
    }

    public alpha(a: number): Color {
        return new Color(this.chromaColor.alpha(a));
    }

    public darken(hslPercent: number): Color {
        return new Color(this.chromaColor.set("hsl.l", this.chromaColor.get("hsl.l") - hslPercent));
    }

    public lighten(hslPercent: number): Color {
        return new Color(this.chromaColor.set("hsl.l", this.chromaColor.get("hsl.l") + hslPercent));
    }

    public toCSS(): string {
        if (this.chromaColor.alpha() === 0) {
            return this.chromaColor.hex();
        }

        return `rgba(${this.chromaColor.rgba().join(",")})`;
    }
}

export default Color;
