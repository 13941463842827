/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import "objectFitPolyfill";
import "normalize.css/normalize.css";
import "../assets/styles/fonts.css";
import "@fontsource/lato/400.css";
import "@fontsource/lato/400-italic.css";
import "@fontsource/lato/700.css";
import React, {memo, ReactNode, useEffect} from "react";
import {Helmet} from "react-helmet";
import {createUseStyles} from "react-jss";
import UI from "./UI";
import {Language} from "./Language";
import Cookies from "js-cookie";
import PageContext from "./PageContext";

const useStyles = createUseStyles({
    body: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        color: UI.colors.veryDarkGrey.toCSS(),
        fontFamily: UI.fonts.lato,
        lineHeight: "1.6em",
        minWidth: 320,
        "& *": {
            boxSizing: "border-box"
        },
        "&[data-no-scroll]": {
            overflow: "hidden"
        }
    },
    "& p": {
        fontSize: "1.375rem",
        marginBottom: "2rem",
        marginTop: 0,
        [UI.media.only("xs")]: {
            fontSize: "1.125rem"
        },
        "&:last-child": {
            marginBottom: 0
        },
        "&:lang(ja)": {
            fontSize: "1rem"
        }
    },
    "& ul": {
        margin: "0 0 2rem",
        "& li": {
            fontSize: "1.375rem",
            lineHeight: "1.6em"
        }
    }
});

interface LayoutProps {
    lang: Language;
    title: string;
    path: string;
    children: ReactNode;
}

function Page(props: LayoutProps) {
    const classes = useStyles();
    useEffect(() => {
        // only save their language preference if the page has a language
        // otherwise we would override the preference of a japanese user if they visited
        // an english only page like the error page or blog
        if (/^\/(en|ja)(\/|$)/.test(window.location.pathname)) {
            const lang = window.document.documentElement.lang.startsWith("ja") ? "ja" : "en";
            Cookies.set("atilika-com-lang", lang, {expires: 365});
        }
    }, []);

    return (
        <PageContext.Provider value={{path: props.path, lang: props.lang}}>
            <Helmet>
                <html lang={props.lang} />
                <title>{`${props.title} | Atilika`}</title>
                <link rel="canonical" href={`https://www.atilika.com${props.path}`} />
                <body className={classes.body} />
            </Helmet>
            <div>{props.children}</div>
        </PageContext.Provider>
    );
}

export default memo(Page);
