/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import {createContext} from "react";
import {Language} from "./Language";

interface PageContextValues {
    path: string;
    lang: Language;
}

const PageContext = createContext<PageContextValues>({path: "/", lang: "en"});

export default PageContext;
