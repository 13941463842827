/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import Color from "./Color";

export type ScreenSize = "xs" | "sm" | "md" | "lg" | "xl";

const breakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
};

const maxBreakpoints = {
    xs: breakpoints.sm - 1,
    sm: breakpoints.md - 1,
    md: breakpoints.lg - 1,
    lg: breakpoints.xl - 1,
    xl: -1
};

export const UI = {
    colors: {
        // Greyscale
        // TODO: change footer to #666
        white: Color.hex("#fff"),
        offWhite: Color.hex("#f9f9f9"),
        superLightGrey: Color.hex("#eaeaea"),
        veryLightGrey: Color.hex("#a7a7a7"),
        lightGrey: Color.hex("#ccc"),
        mediumLightGrey: Color.hex("#b1b1b1"),
        grey: Color.hex("#999"),
        slate: Color.hex("#737373"),
        darkGrey: Color.hex("#6c6c6c"),
        veryDarkGrey: Color.hex("#444343"),
        black: Color.hex("#000"),
        translucentGrey: Color.hex("#6C6C6C").alpha(0.3),
        transparent: Color.hex("#000").alpha(0),
        translucentWhite: Color.hex("#fff").alpha(0.56),

        // Colors
        blue: Color.hex("#3499f7"),
        brightBlue: Color.hex("#3fa5f9"),
        translucentBlue: Color.hex("#64C9FC").alpha(0.66),
        green: Color.hex("#0ed90b"),
        red: Color.hex("#dc3545")
    },
    fonts: {
        lato: "'Lato', 'Arial', sans-serif",
        neoTechStd: "'Neo Tech Std', sans-serif",
        monospace: "'Courier', 'Courier New', monospace"
    },
    breakpoints,
    media: {
        only(size: ScreenSize) {
            switch (size) {
                case "xs":
                    return `@media (max-width: ${maxBreakpoints.xs}px)`;
                case "xl":
                    return `@media (min-width: ${breakpoints.xl}px)`;
                default:
                    return (
                        `@media (min-width: ${breakpoints[size]}px)` +
                        ` and (max-width: ${maxBreakpoints[size]}px)`
                    );
            }
        },
        lte(size: ScreenSize) {
            return `@media (max-width: ${maxBreakpoints[size]}px)`;
        },
        gte(size: ScreenSize) {
            return `@media (min-width: ${breakpoints[size]}px)`;
        },
        between(start: ScreenSize, end: ScreenSize) {
            return (
                `@media (min-width: ${breakpoints[start]}px)` +
                ` and (max-width: ${maxBreakpoints[end]}px)`
            );
        }
    },

    // Assorted
    menuHeight: 52
};

export type UI = typeof UI;

export default UI;
